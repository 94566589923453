import React from 'react';
import styled from 'styled-components';
import LocationTile from './LocationTile';

const MOBILE_BREAKPOINT = '992px';

const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: -120px;

  @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
    margin: 50px 0 60px;
  }
`;

const TilesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-auto-rows: minmax(0, 1fr);
  gap: 45px;

  @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 20px;
  }

  @media screen and (max-width: 414px) {
    grid-template-columns: minmax(0, 1fr);
  }
`;

export default function StoreLocationTiles({ tiles }) {
  return (
    <Container>
      <TilesGrid>
        {tiles.map((tile) => (
          <LocationTile
            bgImage={tile.Image.localFile.childImageSharp.gatsbyImageData}
            locationTitle={tile.Location_Title}
            locationSubtitle={tile.Location_Subtitle}
            locationAddress={tile.Location_Address}
            link={tile.Link_Slug}
          />
        ))}
      </TilesGrid>
    </Container>
  );
}
