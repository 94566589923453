import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import styled from 'styled-components';
import ReactMarkdown from "react-markdown";
import rehypeRaw from 'rehype-raw'

const MOBILE_BREAKPOINT = "768px";

function SingleBanner({banner, index}){
    const MOBILE_BREAKPOINT = "768px";

    const BannerContent = styled.div`
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        padding: 20px 20px;
        h3{
            color: var(--darkgreen);
            font-family: Oswald;
            font-size: 4rem;
        }
        p{
            text-align: left;
        }
        @media (max-width: 900px){
            p{
                text-align: center;
            }
            h3{
                text-align: center;
            }
        }
    `;

    const SingleBanner = styled.div`
        margin: 0px 60px;
        margin-bottom: 145px;
        @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
            margin: 0px 10px;
        }

        h2{
            text-align: center;
            padding: 20px 0px;
            padding-bottom: 70px;
        }
        .banner-image{
            width: 100%;
        }
        .w-bg{
            background-color: white;
        }
    `;

    return(
        <SingleBanner className="row">
            <div className="col-12"><h2>{banner.Header}</h2></div>
            <div className={ (index % 2 == 0) 
                                ? "col-lg-6 col-12 p-0 order-lg-1 order-1 w-bg"
                                : "col-lg-6 col-12 p-0 order-lg-2 order-1 w-bg"
                            }>
                <GatsbyImage 
                    image={banner.Image.localFile.childImageSharp.gatsbyImageData} 
                    className="banner-image" 
                />
            </div>
            <div className={ (index % 2 != 0) 
                                ? "col-lg-6 col-12 p-0 order-lg-1 order-1 w-bg"
                                : "col-lg-6 col-12 p-0 order-lg-2 order-1 w-bg"
                            }>
                <BannerContent>
                    <div>
                        <h3>{banner.Title}</h3>
                        <ReactMarkdown rehypePlugins={[rehypeRaw]} children={banner.Content} />
                    </div>
                </BannerContent>
            </div>
        </SingleBanner>
    );
}

export default function TripleBanner({title, banners}){

    const Title = styled.div`
        width: 100%;
        display: flex;
        justify-content: center;
        h2{
            color: black;
        }
        padding-top: 50px;
        padding-bottom: 70px;
    `;


    return(
        <>
            <Title><h2>{title}</h2></Title>
            <div className="container-fluid">
                {banners.map((banner, index) => (
                    <SingleBanner banner={banner} index={index}/>
                ))}
            </div>
        </>
    );
}