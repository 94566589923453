import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';

import HeroTypeC from '../components/Heroes/HeroTypeC';
import StoreLocationTiles from '../components/Location/StoreLocationTileMini';
import BannerInfoTypeB from '../components/Banners/BannerInfoTypeB';
import TripleBannerLarge from '../components/Location/TripleBannerLarge';
import BannerTypeA from '../components/Banners/BannerTypeA';
import BannerInfoTypeA from '../components/Banners/BannerInfoTypeA';
import MultiArticleV2 from '../components/Articles/MultiArticleV2';

export default function Toronto({ data }) {
  const hero_type_c = data.strapiTorontoAbout.Hero_Type_C;
  const location_tile_section = data.strapiTorontoAbout.Hero_Type_C;
  const info_banner_type_b = data.strapiTorontoAbout.Info_Banner_Type_B;
  const triple_banner_large = data.strapiTorontoAbout.Triple_Info_Banner_Lare;
  const banner_type_middle = data.strapiTorontoAbout.Blog_Banner;
  const info_banner_type_a = data.strapiTorontoAbout.Info_Banner_Type_A;
  const multi_article = data.strapiTorontoAbout.Multi_Articles_Section;
  return (
    <>
      <HeroTypeC
        title={hero_type_c.Header}
        subtitle={hero_type_c.Content}
        heroImage={
          hero_type_c.BackgroundImage.localFile.childImageSharp.gatsbyImageData
        }
      />
      <StoreLocationTiles tiles={location_tile_section.Location_Tile} />
      <BannerInfoTypeB
        description={info_banner_type_b.Content}
        bgImage={
          info_banner_type_b.BackgroundImage.localFile.childImageSharp
            .gatsbyImageData
        }
        buttonLabel={info_banner_type_b.Button.Label}
        buttonLink={info_banner_type_b.Button.Custom_Link_Slug}
        center
      />
      <TripleBannerLarge
        banners={triple_banner_large.Single_Triple_Info_Banner_Large}
      />
      <BannerTypeA
        title={banner_type_middle.Title}
        description={banner_type_middle.Content}
        bannerImage={
          banner_type_middle.Background_Image.localFile.childImageSharp
            .gatsbyImageData
        }
        link="/toronto/delivery"
        buttonLabel="LEARN ABOUT DELIVERY"
      />
      <BannerInfoTypeA
        title={info_banner_type_a.Title}
        description={info_banner_type_a.Content}
        bgImage={
          info_banner_type_a.Image.localFile.childImageSharp.gatsbyImageData
        }
      />
      <MultiArticleV2
        leftarticle={multi_article.Left_Mutli_Article}
        rightarticle={multi_article.Right_Multi_Article}
      />
    </>
  );
}

export const query = graphql`
  query {
    strapiTorontoAbout {
      Hero_Type_C {
        Content
        Header
        BackgroundImage {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100)
            }
          }
        }
        Location_Tile {
          Link_Slug
          Location_Address
          Location_Subtitle
          Location_Title
          Image {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100)
              }
            }
          }
        }
      }
      Info_Banner_Type_B {
        BackgroundImage {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100)
            }
          }
        }
        Button {
          Custom_Link_Slug
          Label
        }
        Content
      }
      Triple_Info_Banner_Lare {
        Single_Triple_Info_Banner_Large {
          Header
          Title
          Content
          Image {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
      Info_Banner_Type_A {
        Title
        Content
        Image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      Blog_Banner {
        Content
        Title
        Blog_Button_Link {
          Button_Label
          Link_Slug
        }
        Background_Image {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100)
            }
          }
        }
      }
      Multi_Articles_Section {
        Left_Mutli_Article {
          article {
            Title
            slug
            Preview_Text
            BannerImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 100)
                }
              }
            }
          }
        }
        Right_Multi_Article {
          article {
            Title
            Preview_Text
            slug
            BannerImage {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
  }
`;
