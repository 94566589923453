import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { BgImage } from 'gbimage-bridge';

import { ImArrowUpRight2 } from 'react-icons/im';

const MOBILE_BREAKPOINT = '768px';

const Wrapper = styled.div`
  .bg-image {
    background: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.75) 50%,
      rgba(0, 0, 0, 0.5) 100%
    );
    height: 100%;
  }
`;

const Container = styled(Link)`
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  padding: 20px 30px 0;
  box-sizing: border-box;
  background-color: white;
  border: 2px solid #a1c654;
  border-radius: 3px;
  transition: all 0.3s;
  cursor: pointer;

  .arrow-icon {
    position: absolute;
    right: 20px;
    bottom: 20px;
    font-size: 25px;
    color: #a1c654;
  }

  & > * {
    transition: all 0.3s;
  }

  &:hover {
    background-color: transparent;
    border-color: transparent;

    & > * {
      color: white;
    }
  }

  @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
    padding: 10px 10px 0;

    .arrow-icon {
      right: 10px;
      bottom: 10px;
    }
  }
`;

const CityName = styled.h3`
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 600;
  font-size: 64px;
  line-height: 120%;
  text-transform: uppercase;
  color: #a1c654;
  @media screen and (max-width: 1200px) {
    font-size: 48px;
  }
  @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
    font-size: 36px;
  }
`;

const DistrictName = styled.h4`
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 120%;
  text-transform: uppercase;
  color: #a1c654;

  @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
    font-size: 24px;
  }
`;

const StreetName = styled.div`
  margin-top: 5px;
  margin-bottom: calc(80px + 5vw);
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #252e2f;

  @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
    font-size: 16px;
  }
`;

export default function CategoryCard({
  bgImage,
  locationTitle,
  locationSubtitle,
  locationAddress,
  link,
  hasGradient = false,
}) {
  return (
    <Wrapper>
      <BgImage
        image={bgImage}
        preserveStackingContext
        className={hasGradient ? 'bg-image' : ''}
        style={{
          width: '100%',
          minWidth: '177px',
          backgroundPosition: 'center',
          borderRadius: '3px',
          overflow: 'hidden',
        }}
      >
        <Container to={link}>
          <CityName>{locationTitle}</CityName>
          <DistrictName>{locationSubtitle}</DistrictName>
          <StreetName>{locationAddress}</StreetName>
          <ImArrowUpRight2 className="arrow-icon" />
        </Container>
      </BgImage>
    </Wrapper>
  );
}
